import React, { useState } from "react";
import { createRoot } from 'react-dom/client';
import FilerobotImageEditor, {
    TABS,
  } from 'react-filerobot-image-editor';
import "./styles.css";

const App = () => {
  const src = "https://s3.us-central-1.wasabisys.com/photodelivery.hommati.com/1271/photodelivery_full/3600_2400_des_moines_ia-08-26-2022-1.jpg";
  const [show, toggle] = useState(false);

  return (
    <div>
      <h1>Hommati Image Editor</h1>

      <FilerobotImageEditor
        show={show}
        source={src}
        Text={{ text: 'Hommati Image Editor' }}
        onClose={() => {
          toggle(false);
        }}
        tabsIds={[TABS.ADJUST, TABS.RESIZE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
        defaultTabId={TABS.ADJUST} // or 'Annotate'
      />
    </div>
  );
};
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
